
import { createState } from './types'

import mutations from './mutations'
import  getters from './getters'

let state = ()=>{
    return createState();
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}