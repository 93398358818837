import { createStateMix } from './types'
import mutations from './mutations'
import getters from './getters'

let state = () => {
  return createStateMix();
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
