// @flow
import type {IState} from '~/store/spot/types';
import createState from '~/store/spot/types';
import LocalCache, {CacheKey} from '~/utils/LocalCache';
import {createDefaultUserConfig} from '~/store/future/types';

export const SPOT_MUTATIONS_TYPE = {
  SET_SYMBOL: 'setSymbol',
  SET_DEPTH_PRICE: 'setDepthPrice',
  SET_BUY_DEPTH_PRICE: 'setBuyDepthPrice',
  SET_SELL_DEPTH_PRICE: 'setSellDepthPrice',
  CHANGE_MARKET_DETAIL_READY: 'changeMarketDetailReady',
  SET_MARKET_DETAIL: 'setMarketDetail',
  SET_ALL_TICKER: 'setAllTicker',
  REFRESH_LEFT_COIN_COUNT: 'refreshLeftCoinCount',
  REFRESH_RIGHT_COIN_COUNT: 'refreshRightCoinCount',
  CLEAR_TRADE_STATE: 'clearTradeState',
  SET_ENTRUST_STORE: 'setEntrustStore',
  CHANGE_ENTRUST_LOADING: 'changeEntrustLoading',
  CHANGE_SWITCH_VALUE: 'changeSwitchValue',
  SET_LAYOUT: 'spotSetLayout',
  UPDATE_USER_CONFIG: 'updateUserConfig',
  UPDATE_TRADE_PRICE: 'updateTradePrice'
};

export default {
  [SPOT_MUTATIONS_TYPE.SET_SYMBOL](state: IState, newValue: string) {
    state.symbol = newValue;
    state.currentEntrust.length = 0;
    state.historyEntrust.length = 0;
    state.fentrustlogs.length = 0;
  },
  [SPOT_MUTATIONS_TYPE.SET_DEPTH_PRICE](state, newValue) {
    state.depthPrice = newValue;
  },

  [SPOT_MUTATIONS_TYPE.SET_BUY_DEPTH_PRICE](state, newValue) {
    state.buyDepthPrice = newValue;
  },

  [SPOT_MUTATIONS_TYPE.SET_SELL_DEPTH_PRICE](state, newValue) {
    state.sellDepthPrice = newValue;
  },

  [SPOT_MUTATIONS_TYPE.CHANGE_MARKET_DETAIL_READY](state, newValue) {
    if (!newValue) {
      state.entrustLoading = true;
    }
    state.marketDetailReady = newValue || false;
  },

  [SPOT_MUTATIONS_TYPE.SET_MARKET_DETAIL](state, newValue) {
    // 一定要把详细数据更新后，再通知状态就绪
    Object.assign(state.marketDetail, newValue);
    state.marketDetailReady = true;
  },
  [SPOT_MUTATIONS_TYPE.SET_ALL_TICKER](state, newValue) {
    state.allTicker = newValue;
    for (let ticker of state.allTicker) {
      if (state.symbol === ticker.leftCoinName + '_' + ticker.rightCoinName) {
        state.marketDetail = Object.assign({}, state.marketDetail, ticker);
        state.marketDetailReady = true;
      }
    }
  },
  // 更新现货最新价
  [SPOT_MUTATIONS_TYPE.UPDATE_TRADE_PRICE](state, newValue) {
    if (newValue && state.marketDetail && state.marketDetail.last) state.marketDetail.last = newValue;
  },
  [SPOT_MUTATIONS_TYPE.REFRESH_LEFT_COIN_COUNT](state, newValue) {
    state.leftCoinCount = newValue;
  },

  [SPOT_MUTATIONS_TYPE.REFRESH_RIGHT_COIN_COUNT](state, newValue) {
    state.rightCoinCount = newValue;
  },

  /**
   * 清空币币交易页面的状态
   * @param state
   * @param newValue 可选的清空，否则使用默认状态
   */
  [SPOT_MUTATIONS_TYPE.CLEAR_TRADE_STATE](state: IState, newValue) {
    Object.assign(state, createState());
    state.layout = LocalCache.get(CacheKey.SPOT_LAYOUT) || 'center';
  },

  [SPOT_MUTATIONS_TYPE.SET_ENTRUST_STORE](state, payload) {
    let oldState = state[payload.type];
    if (!payload.data) {
      // 如果后台没有给定这个数据，那么将忽略该数据的更新
      // null 或者 undefined 就暂时不处理
      return;
    }
    oldState.length = 0;
    oldState.push(...payload.data);
  },

  [SPOT_MUTATIONS_TYPE.CHANGE_ENTRUST_LOADING](state, value) {
    state.entrustLoading = value;
  },

  /**
   * 只更改开关的状态
   * @param state
   * @param newValue
   */
  [SPOT_MUTATIONS_TYPE.CHANGE_SWITCH_VALUE](state, newValue) {
    state.switchValue = !!newValue;
  },
  [SPOT_MUTATIONS_TYPE.SET_LAYOUT](state, newValue) {
    if (newValue) {
      state.layout = newValue;
      LocalCache.set(CacheKey.SPOT_LAYOUT, newValue);
    } else {
      state.layout = LocalCache.get(CacheKey.SPOT_LAYOUT);
    }
  },
  /**
   * 更新用户配置
   * @param state
   * @param newConfig
   */
  [SPOT_MUTATIONS_TYPE.UPDATE_USER_CONFIG](state, newConfig) {
    newConfig = newConfig || createDefaultUserConfig();
    newConfig.isReady = true;
    state.userConfig = newConfig;
  },
};
