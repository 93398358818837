// @flow
import {SPOT_MUTATIONS_TYPE} from '~/store/spot/mutations';
import {createDefaultUserConfig} from '~/store/spot/types';

export const SPOT_ACTIONS_TYPE = {
  INIT_ALL: 'initAll',
  REFRESH_USER_ASSETS: 'refreshUserAssets',
  REFRESH_USER_ENTRUST: 'refreshUserEntrust',
  UPDATE_USER_CONFIG: 'updateUserConfig',
};

export default {
  [SPOT_ACTIONS_TYPE.INIT_ALL](store, symbol) {
    const {commit, state, rootState} = store;

    // 更新用户的配置
    let userIsLogin = rootState.userReady && rootState.user.loginName;
    let savedConfig = localStorage.getItem(
      rootState.user.userId + '::spot::config::' + symbol,
    );
    let openTime =
      localStorage.getItem(
        rootState.user.userId + '::spot::config::openTime') || -1;
    try {
      let parsedConfig =
        savedConfig && userIsLogin
          ? JSON.parse(savedConfig)
          : createDefaultUserConfig();
      parsedConfig.agreedTime = Number(openTime);
      let mergeConfig = Object.assign({}, state.userConfig, parsedConfig);
      commit(SPOT_MUTATIONS_TYPE.UPDATE_USER_CONFIG, mergeConfig);
    } catch (e) {
      console.error('User Config Is Broken!');
    }
  },
  /**
   * 刷新用户的左右币资产
   */
  [SPOT_ACTIONS_TYPE.REFRESH_USER_ASSETS]({commit, state}, currentWallets) {
    let leftCoinName = state.symbol.split('_')[0] || '',
      rightCoinName = state.symbol.split('_')[1] || '';
    for (let coin of currentWallets) {
      let shortName = coin.fshortname || '';
      if (shortName.toUpperCase() === leftCoinName.toUpperCase()) {
        commit('refreshLeftCoinCount', Number(coin.ftotal).toFixed(8));
      }
      if (shortName.toUpperCase() === rightCoinName.toUpperCase()) {
        commit('refreshRightCoinCount', Number(coin.ftotal).toFixed(8));
      }
    }
  },

  /**
   * 更新所有的委托列表
   * @param commit
   * @param data
   */
  [SPOT_ACTIONS_TYPE.REFRESH_USER_ENTRUST]({commit}, data) {
    commit('setEntrustStore',
      {type: 'currentEntrust', data: data.currentEntrust});
    commit('setEntrustStore',
      {type: 'historyEntrust', data: data.historyEntrust});
    commit('setEntrustStore', {type: 'fentrustlogs', data: data.fentrustlogs});
    // 关闭loading
    commit('changeEntrustLoading', false);
  },

  /**
   * 更新用户配置
   * @param store
   * @param newConfig
   */
  [SPOT_ACTIONS_TYPE.UPDATE_USER_CONFIG](store, newConfig) {
    const {commit, state, rootState} = store;
    let config = Object.assign({}, state.userConfig, newConfig);
    commit(SPOT_MUTATIONS_TYPE.UPDATE_USER_CONFIG, config);
    let uid = rootState.user.userId;
    localStorage.setItem(
      uid + '::spot::config::' + String(state.symbol),
      JSON.stringify(config),
    );
    if (newConfig.hasOwnProperty('agreedTime')) {
      localStorage.setItem(uid + '::spot::config::openTime',
        newConfig['agreedTime']);
    }
  },
};
