// @flow
import { Decimal } from 'decimal.js';
import { SUPPORTED_THEME } from 'bgconfig';

/**
 * 本文件负责声明各种实体，以及暴露生产默认类型的工厂
 */
export opaque type ProductCode = string;

/**
 * 统一叫法：合约相关精度配置实体，称作ContractPlaceInfo
 */
export type ContractPlaceInfo = {
  serverCalPlace: number, // 服务器计算用精度
  delegatePricePlace: number, // 委托价格精度
  volumePlace: number, // 显示用数量精度
  pricePlace: number, // 显示用一般价格精度
  capitalRatePlace: number, // 资金费率精度
  transferInOutPlace: number, // 转入转出精度
  propertyPlace: number, // 显示用资产精度
  marginRatePlace: number, // 保证金率精度
  propertyHighPlace: number, // 高精度显示资产
  baseCoinPlace: number // 张数转币量所用精度
};
/**
 * 统一叫法：合约的相关配置信息实体，称为ContractInfo
 */
export type ContractInfo = {
  productCode: string, // 合约代号
  status: 0 | 1 | 2, // 合约状态 1-正常 0-下架 2-维护
  baseSymbol: string, // 左币符号
  quoteSymbol: string, // 保证金符号
  pricedSymbol: string, // 右币符号
  planMarketPrice: boolean, // 是否支持闪电计划
  marketPriceOpenLimitSize: number, // 市价开仓单笔数量限制
  marketPriceCloseLimitSize: number, // 市价平仓单笔数量限制
  autoAppendMargin: boolean, // 是否支持自动追加保证金
  forwardContractFlag: boolean, // ture-正向合约
  simulation: boolean, // true-模拟盘
  preValue: number, // 面值
  priceEndStep: number, // 委托价格步长
  longLimitBurstPrice: number, // 多仓爆仓价下限，小于该值不爆仓
  shortLimitBurstPrice: number, // 空仓爆仓价上限，大于该值显示不爆仓
  placeConfig?: ContractPlaceInfo,
  limitPriceFlag: 1 | 2 | 3, // 计算委托价格上下限用的参考，1-指数 2-市场 3-最宽限
  feeRate: number, // 手续费率
  openCostUpRatio: number, // 开仓成本上浮比例
  openCostFeeUpRatio: number, // 开仓手续费上浮比例
  buyLimitPriceRatio: number, // 最高买价上限比例
  sellLimitPriceRatio: number, // 最低卖价下限比例
  maxTradingUnit: number, // 最大交易张数
  maxLeverage: number, // 最大杠杆倍数
  webMarginScale: number[], // 杠杆节点
  priceUseCapitalRate: boolean, // 计算爆仓价是否使用资金费用
  priceUseFeeRate: boolean, // 20200819 新增爆仓价是否使用手续费
  indexDataSource: string, // 指数来源
  trackingSupport: boolean, // 是否支持跟单
  supportCrossMarginMode: boolean, // 是否支持全仓
  splitMargin: boolean, // 是否支持分仓
  hotP: boolean, // 是否是热门交易对
  newP: boolean, // 是否是新交易对
  frozenMarginFloatingRatio: string,
  frozenFillFeeFloatingRatio: string,
  takerFeeRate: String,
  makerFeeRate: string,
};

/**
 * 统一叫法：仓位档位信息
 */
export type PositionLeverageLevel = {
  endPositionValue: number, // 终止合約價值
  maintenanceMarginRate: number, // 维持保证金率
  maxLeverage:number, // 对应杠杆
  startPositionValue: Number, // 起始合約價值
  tier: number // 档位index
};

/**
 * 统一叫法：市场信息===ticker
 */
export type Ticker = {
  productCode: ProductCode,
  price: string, // 市场价格
  fairPrice: string, // 合理标记价格
  indexPrice: string, // 指数价格
  change: string, // 涨跌幅
  high: string, // 最高价
  low: string, // 最低价
  vol: string, // 成交量
  holding: string, // 持仓量
  fundsRate: string, // 资金费率
  newP?: boolean, // 新交易对
  hotP?: boolean // 热门交易对
};

/**
 * 统一叫法：盘口信息
 * [0] 价格，[1] 数量
 */
export type DepthInfo = [string, string];

/**
 * 统一叫法：成交记录
 * [0] 时间 [1] 价格 [2] 数量 [3] 类型 1-买 2-卖
 */
export type HistoryInfo = [string, string, string, number];

/**
 * 统一叫法：资金费率（具体资金费率值，下一结算时间，可用于倒计时）
 */
export type FundingRateInfo = {
  contractId : string,
  contractName : string,
  fundingTime : string,
  fundingTimestamp :string,
  markPrice :string,
  indexPrice : string,
  fundingRate : string,
  forecastFundingRate : string,
  previousFundingRate : string,
  previousFundingTimestamp : string,
  premiumIndex : string,
  avgPremiumIndex : string,
  premiumIndexTimestamp :string,
  impactMarginNotional :string,
  impactAskPrice : string,
  impactBidPrice : string,
  interestRate :string,
  predictedFundingRate : string,
  fundingRateIntervalMin : string,
  settlement :false
};

/**
 * 统一叫法：用户资产（将原始数据流拆分得到）
 */
export type UserAssetsInfo = {
  balance: string, // 可用
  balanceText: string, // 显示用可用
  used: string, // 已用
  longLeverage: string, // 多仓杠杆
  shortLeverage: string, // 空仓杠杆
  freeze: string, // 冻结量
  cashgift: string, // 体验金
  longAccountAchievedProfits: string, // 多仓已实现盈亏
  shortAccountAchievedProfits: string, // 空仓已实现盈亏
  longAccountDelegateCount: string, // 多仓开仓委托张数
  shortAccountDelegateCount: string,
  holdModeEnum: string, // 原始持仓模型枚举 FIXED_MARGIN-逐仓 CROSS_MARGIN-全仓
  subHoldModeEnum: string, // 原始持仓模型枚举 MERGED-合仓 SPLIT-分仓
  isFixedMode: boolean, // true-逐仓模式
  isCrosssMode: boolean, // true-合仓模式
  isSplitMode: boolean, // true-分仓模式
  dTotalUpnl: null | Decimal, // 总未实现盈亏
  dTotalUsed: null | Decimal, // 总已用
  dTotalProfits: null | Decimal, // 总账户权益（仓位不是不同计算不同）
  crossMarginRateText: string, // 全仓模式保证金率(仅显示用)
  extraMM: string // 20200818新增，用户手续费，参与保证金率计算
};

/**
 * 统一叫法：当前委托
 */
export type CurrentEntrust = {
  amount: string, // 委托数量
  direction: number, // 方向 [1-10]
  done: string, // 已成交量张数
  forcePrice?: string, // 爆仓价格
  forceTime?: string, // 爆仓时间
  id: string, // 委托ID，撤单凭证
  orderType: 0 | 1, // 0-限价 1-市价
  price: string, // 委托价格
  source: number, // 来源 [1-15]
  status: number, // 状态 [1-5]
  time: string, // 委托时间
  presetTakeProfitPrice?: string, // 预设止盈价格
  presetStopLossPrice?: string // 预设止损价格
};

/**
 * 历史委托
 */
export type HistoryEntrust = {
  amount: string, // 委托张数
  arvPrice: string, // 成交均价
  dealCount: string, // 成交数量
  delegatePrice: '9526.0', // 委托价格
  direction: number, // 方向
  forcePrice: string, // 强平价格
  forceTime: string, // 强平时间
  orderType: number, // 委托类型
  pnl: string, // 已实现盈亏
  source: number, // 来源 系统委托还是手动委托
  status: 3, // 状态，完全成交/部分撤销等等
  time: string // 时间
};

/**
 * 统一叫法：当前计划（计划委托+止盈止损）
 */
export type CurrentPlans = {
  amount: string, // 委托张数
  createTime: string, // 创建时间
  createTradePrice: string, // 创建时市场价，用于判断触发条件大于等于
  direction: number, // 方向[1-10] 1开多 2开空 3平多 4平空 5强制减多 6强制减空 7协议平仓 +  8协议平仓 9爆仓平多 10爆仓平空
  executeCount: string, // 已执行张数
  executePrice: string, // 执行价格
  id: string, // 计划ID，用于撤销
  planType: 0 | 1 | 2, // 计划类型 0-普通计划 1-止盈 2-止损
  status: 1 | 2 | 3 | 4, // 计划状态
  triggerPrice: string, // 触发价格
  triggerType: number // 触发类型 1-限价 x-市价
};

/**
 * 历史计划
 */
export type HistoryPlan = {
  amount: string, // 数量
  cancelReason: string, // 取消原因
  createTime: string, // 创建时间
  createTradePrice: string, // 创建时市场价格
  direction: number, // 方向[1-10]
  executeCount: string, // 执行数量
  planType: number, // 计划类型 0-普通计划 1-止盈 2-止损
  status: number, // 计划状态
  triggerPrice: string, // 触发价格
  triggerType: number // 触发类型
};

/**
 * 委托的成交明细
 */
export type EntrustDetail = {
  amount: string, // 成交数量
  dealPrice: string, // 成交均价
  direction: 1, // 方向[1-10]
  fee: string, // 手续费
  forcePrice: string, // 强平价格
  forceTime: string, // 强平时间
  pnl: string, // 已实现盈亏
  time: string // 时间
};

/**
 * 当前跟单和当前单带实体类似
 */
export type TraceOrderInfo = {
  teacherId: string, // 带单者ID，有这个字段表示为跟单类型
  closeDealCount: string, // 平仓张数
  orderNo: string, // 订单号
  productCode: string, // 合约名称
  position: string, // 仓位方向 多1空0
  openLevel: string, // 杠杆倍数
  openDelegateCount: string, // 开仓委托张数
  openDealCount: string, // 开仓成交张数
  openAvgPrice: string, // 开仓均价
  openMarginCount: string, // 累计放入保证金
  closeAvgPrice: string, // 平仓均价
  achievedProfits: string, // 已实现盈亏
  stopProfitPrice: string, // 止盈价格 49%
  stopLossPrice: string, // 止损价格 - 19%
  openTime: number, // 开仓时间
  closeTime: number, // 平仓时间
  openFee: string, // 开仓累计手续费
  closeFee: string, // 平仓累计手续费
  capitalPay: string, // 资金费率支出
  capitalReceive: string, // 资金费率获取
  status: number // 0初始状态，1开仓完成状态，2平仓完成状态
};
export type TickerInfo = {
  contractId: string,
  priceChange: string,
  priceChangePercent: string,
  trades: string,
  size: string,
  value: string,
  high: string,
  low: string,
  open: string,
  close: string,
  highTime: string,
  lowTime: string,
  startTime: string,
  endTime: string,
  lastPrice: string,
  markPrice: string
};

/**
 * 统一叫法：ADL减仓队列（单位杠杆净盈利排序）
 * [0] 区间起始 [1] 区间结束
 */
export type ADLInfo = [number, number];

/**
 * 统一叫法：保证金流水（由于逐仓模式下以保证金账户为最小单位）
 * 全仓模式将引用全新的财务记录NEW
 */
export type PositionHistory = {
  position: string, // 1多仓 2空仓
  time: string, // 时间
  type: number, // 类型
  vol: string // 具体数量
};

/**
 * 统一叫法：财务记录NEW
 */
export type FinancialRecord = {
  positionShowType: number, // 仓位类型 0-无 1-全仓 2-逐仓多头 3-逐仓多头
  bizTime: number, // 时间
  operationType: 0, // 减少保证金 增加保证金 开仓保证金 资金费用 手续费 已实现盈亏 平仓结算 协议平仓结算 爆仓结算
  amount: string, // 金额
  count: string, // 张数
  fee: string, // 手续费
  remarkType: string // 备注类型
};

/**
 * 统一叫法：原始仓位信息
 */
export type PositionInfo = {
  amount: string, // 持仓数量
  autoAppendMargin: number, // 是否开启自动追加保证金， 全仓将不具备这个字段
  delegateCount: string, // 委托张数
  freezeCount: string, // 平仓冻结张数
  margin: string, // 保证金量
  openPrice: string, // 开仓均价
  pnl: string, // 已实现盈亏
  position: number, // 1多仓 2空仓
  reducePrice: string, // 预估强平价
  openOrderNo: string // 分仓仓位的开仓订单编号
};

/**
 * 显示用仓位信息（包含一些计算量）
 * 未实现盈亏，回报率，自动减仓队列位置，保证金率
 */
export type FixedPositionInfo = PositionInfo & {
  holdCount: number, // 持仓+冻结 表达完整的仓位
  dUpnl: null | Decimal, // 未实现盈亏 in decimal
  dMarginRate: null | Decimal, // 保证金率 in decimal
  dRoe: null | Decimal, // 回报率 in decimal
  dValue: null | Decimal, // 开仓均价-仓位价值 in decimal
  dCurrentValue: null | Decimal, // 合理标记价格-仓位价值 in decimal
  adlQueueIndex: number // 自动减仓队列排序
};

/**
 * 最大可开张数
 */
export type MaxOpenSizeType = {
  maxLongSize: number,
  maxShortSize: number
};

export type ContractModeType = {
  marginMode: string, // SHARED, CROSS, ISOLATED
  positionMode: string, // HEDGE, ONE_WAY
  separatedMode: string // COMBINED, SEPARATED
};

/**
 * 合约委托参数
 */
export type ContractPlaceParam = {
  amount: number, // 张数
  caitalPwd?: string, // 资金密码
  requestTime?: number, // 资金密码时间戳
  matchType: number, // 0-限价 1-市价
  productCode: ProductCode, // 合约
  side: number, // 仓位方向 1-多仓 2-空仓
  timeInForce?: number, // 0-普通 1-po 2-ioc 3-fok
  tradePrice: number, // 委托价格
  type: number // 方向 1-开仓 2-平仓
};

export type CoinInfoType = {
  coinId: string,
  coinName: string,
  stepSize: string,
  iconUrl: string,
  enableCollateral: boolean
};

/**
 * 開倉成本
 */
export type OpenCostType = {
  openLongCostSize: number, // 多倉開倉成本
  openShortCostSize: number // 空倉開倉成本
};

/**
 * 合约交易Vuex状态树
 */
export type IState = {
  apiBaseUrl: string, // api基础地址
  asks: DepthInfo[], // 卖盘
  asksTotal: number, // 卖盘15档总量，
  assets: UserAssetsInfo, // 用户资产信息
  assetsAll: UserAssetsInfo[], // 用户资产信息
  assetsInfoAll: any, //  新合約 用户资产信息
  bids: DepthInfo[], // 买盘
  bidsTotal: number, // 买盘15档总量
  bonus: number, // 合約Pro體驗金
  coinList: CoinInfoType[], // 新合約 原始币种列表
  contractId: number, // 新合約 合约ID
  contractIdLeverageDialog: number, //调整杠杆弹窗的幣種
  contractInfo: ContractInfo,
  contractList: ContractInfo[], // 新合約 原始合约配置列表
  contractPlaceInfo: ContractPlaceInfo,
  currentContractId: number, //  新合約 当前合约ID
  currentContractMode: ContractModeType, // 新合約 当前合约模式
  currentEntrust: CurrentEntrust[], // 当前委托信息
  currentEntrustsAll: CurrentEntrust[], // 新合約 当前委托
  currentPlans: CurrentPlans[], // 当前计划
  currentPlansAll: any, // 新合約 当前计划
  entrustDetail: EntrustDetail[], // 成交明细
  financialRecords: FinancialRecord[], // 新保证金流水
  fundingRateInfo: FundingRateInfo, // 资金费用相关信息
  history: HistoryInfo[], // 成交记录，增量更新
  historyEntrusts: HistoryEntrust[], // 历史委托
  historyPlans: HistoryPlan[], // 历史计划
  layout: string, // 新增多布局模式表示字段
  layoutReady: boolean, // 布局已设置，可以开始布局
  longADLQueue: ADLInfo[], // 多仓自动减仓队列范围信息
  longLeverage: number, //  新合約 多仓杠杆
  longPositionInfo: FixedPositionInfo, // 多仓仓位信息
  maxOpenSize: MaxOpenSizeType, //  新合約 最大开仓张数
  newAllTicker: {}, // 全体市场信息
  newKlineLastCloseData: {
    price: String,
    prevPrice: String
  },
  openCost: OpenCostType, // 新合約 開倉成本
  position: PositionInfo[], // 原始仓位信息
  positionAll: PositionInfo[], // 原始仓位信息
  positionHistory: PositionHistory[], // 保证金流水
  positionLeverageLevel: PositionLeverageLevel[], // 仓位档位梯度信息，切换交易对应当清空
  productCode: ProductCode,
  reversePositionChecked: boolean, // 反手是否包含委托部分，默认true
  shortADLQueue: ADLInfo[], // 空仓自动减仓队列范围信息
  shortLeverage: number, // 新合約 空仓杠杆
  shortPositionInfo: FixedPositionInfo, // 空仓仓位信息
  showCheckLever: boolean, // 手机绑定提示的显示控制
  showContractProtocol: boolean, // 开通合约协议的显示控制
  showLeverageDialog: boolean, // 调整杠杆弹窗的显示控制
  stateNumber: number, // 状态码 0-初始态 1-配置就绪态 2-ticker就绪态 3-Pending态  (ticker就绪态可以认为是最终就绪态)
  systemTime: number, // 系统时间
  ticker: Ticker, // 当前交易对市场信息
  traceOrders: TraceOrderInfo[], // 当前跟单/当前带单
  tradeLever: number, // 用户身份 -1表示不明确，当前跟单/当前带单tab都不显示 0-普通用户 1-交易员
  typeLeverageDialog: number, // 调整杠杆的方向
  unitType: number, // 交易数量单位，仅影响委托组件和深度组件
  userConfig: UserConfig, // 用户配置保存在localStorage中
  userInfo: UserInfo, //  新合約 用户信息
  userSelectDepth: string, // 用户点击盘口时选择的信息（仅价格）
  userSelectInfo: {
    price: string,
    amount: string
  }, // 用户点击盘口选择的信息，价格+数量
};

// 支持的布局
export const SUPPORTED_LAYOUT = ['left', 'center', 'right'];

/**
 * 包含挂单吃单选项在内的用户设置
 */
export type UserConfig = {
  agreedTime: number, // 同意使用的时间
  alertForLazyOp: boolean, // 挂单吃单是否提示
  alertForProfitControl: boolean, // 止盈止损是否提示
  switchForLazy: boolean, // 是否使用吃单挂单
  directionForLazy: number, // 挂单吃单的模式 1-开仓 2-平仓
  checkForMakerPO: boolean, // 挂单是否使用只做Maker模式
  checkForTakerIOC: boolean, // 吃单是否使用IOC
  checkForTakerFOK: boolean, // 吃单是否使用FOK
  checkForOpenMode: number, // 开仓数量模式 1-百分比 2-盘口
  openPercent: number, // 开仓数量百分比模式具体百分比
  checkForCloseMode: number, // 平仓数量模式 1-百分比 2-盘口
  closePercent: number, // 平仓数量百分比模式具体百分比
  isReady: boolean // 配置是否就绪
};

export interface ContractType {
  contractId: string;
  contractName: string;
  baseCoinId: string;
  quoteCoinId: string;
  tickSize: string;
  stepSize: string;
  minOrderSize: string;
  maxOrderSize: string;
  maxPositionSize: string;
  riskLimitTierList: RiskLimitTierList[];
  frozenMarginFloatingRatio: string;
  frozenFillFeeFloatingRatio: string;
  takerFeeRate: string;
  makerFeeRate: string;
  liquidateFeeRate: string;
  defaultLeverage: string;
  buyLimitPriceRatio: string;
  sellLimitPriceRatio: string;
  longLimitLiquidatePrice: string;
  shortLimitLiquidatePrice: string;
  marketOpenLimitSize: string;
  marketCloseLimitSize: string;
  enableTrade: boolean;
  enableDisplay: boolean;
  enableOpenPosition: boolean;
  supportMarginModeList: any[];
  supportSeparatedModeList: any[];
  supportPositionModeList: any[];
  fundingInterestRate: string;
  fundingImpactMarginNotional: string;
  fundingMaxRate: string;
  fundingMinRate: string;
  fundingDailySettleTime: string[];
  displayDigitMerge: string;
  displayMaxLeverage: string;
  displayMinLeverage: string;
  displayWebLeverageSettingList: string[];
  displayAppLeverageSettingList: string[];
  displayNew: boolean;
  displayHot: boolean;
  extraConfig: ExtraConfig;
  placeConfig: PlaceConfig;
  priceUseFeeRate: boolean;
  supportTracing: boolean;
  supportPlanMarket: boolean;
}
export interface RiskLimitTierList {
  tier: number;
  startPositionValue: string;
  endPositionValue: string;
  maxLeverage: string;
  maintenanceMarginRate: string;
}
export interface ExtraConfig {
  productCode: string;
  iconUrl: string;
  preValue: string;
  forwardContractFlag: string;
  status: string;
  indexDataSource: string;
  simulation: string;
  contractSort: string;
}
export interface PlaceConfig {
  serverCalPlace: string;
  delegatePricePlace: string;
  volumePlace: string;
  pricePlace: string;
  capitalRatePlace: string;
  transferInOutPlace: string;
  propertyPlace: string;
  marginRatePlace: string;
  propertyHighPlace: string;
  baseCoinPlace: string;
}

/**
 * 用户信息
 * 登入后取得
 * @export
 * @interface UserInfo
 */

export interface UserInfo {
  id: string;
  userId: string;
  clientAccountId: string;
  defaultFeeSetting: DefaultFeeSetting;
  contractIdToFeeSettingMap: ContractIdToFeeSettingMap;
  contractIdToModeSettingMap: ContractIdToModeSettingMap;
  contractIdToLeverageSettingMap: ContractIdToLeverageSettingMap;
  extraType: string;
  extraDataJson: string;
  status: string;
  createdTime: string;
  updatedTime: string;
  systemAccount: boolean;
}

export interface OrderPriceObj {
  longPrice:String;
  shortPrice:String;
}
export interface DefaultFeeSetting {
  takerFeeRate: string;
  makerFeeRate: string;
  feeDiscount: string;
  setFeeRate: boolean;
  setFeeDiscount: boolean;
}

export interface ContractIdToFeeSettingMap {}

export interface ContractIdToModeSettingMap {}

export interface ContractIdToLeverageSettingMap {}

/**
 * getters类型常量
 */
export const GETTER_TYPES = {
  MAINTENANCE_MARGIN_RATE: 'MAINTENANCE_MARGIN_RATE',
  LEVERAGE_MAX_HOLD_NEW: 'LEVERAGE_MAX_HOLD_NEW',
  HOLD_MAX_LEVERAGE_NEW: 'HOLD_MAX_LEVERAGE_NEW',
  CAN_MAX_OPEN_NEW: 'CAN_MAX_OPEN_NEW',
  _CAN_MAX_OPEN_NEW: '_CAN_MAX_OPEN_NEW',
  SEL_CAN_MAX_OPEN_NEW: 'SEL_CAN_MAX_OPEN_NEW',
  _SEL_CAN_MAX_OPEN_NEW: '_SEL_CAN_MAX_OPEN_NEW',
  STEPPED_PRICE_NEW: 'STEPPED_PRICE_NEW',
  CHECK_INPUT_PRICE_NEW: 'CHECK_INPUT_PRICE_NEW',
  TRANSFER_AMOUNT_TO_COUNT_NEW: 'TRANSFER_AMOUNT_TO_COUNT_NEW',
  TRANSFER_COUNT_TO_AMOUNT_NEW: 'TRANSFER_COUNT_TO_AMOUNT_NEW',
  GET_COIN_LIST_NEW: 'GET_COIN_LIST_NEW'
};

/**
 * mutations类型常量
 */
export const MUTATION_TYPES = {
  PENDING_NEW_STATE: 'PENDING_NEW_STATE',
  INIT_THEME: 'INIT_THEME',
  SET_APIBASEURL: "SET_APIBASEURL",
  SET_THEME: 'SET_THEME',
  SET_LAYOUT: 'SET_LAYOUT',
  SET_NEW_PRODUCT_CODE: 'SET_NEW_PRODUCT_CODE',
  SET_SEL_PRODUCT_CODE: 'SET_SEL_PRODUCT_CODE',
  NEW_INIT_CONTRACT_LIST: 'NEW_INIT_CONTRACT_LIST',
  SET_NEW_CONTRACT_INFO: 'SET_NEW_CONTRACT_INFO',
  SET_SEL_CONTRACT_INFO: 'SET_SEL_CONTRACT_INFO',
  SET_NEW_CONTRACT_PLACE_INFO: 'SET_NEW_CONTRACT_PLACE_INFO',
  SET_NEW_POSITION_RISK_LEVEL: 'SET_NEW_POSITION_RISK_LEVEL',
  SET_POSITION_LEVERAGE_LEVEL_ALL: 'SET_POSITION_LEVERAGE_LEVEL_ALL',
  UPDATE_NEW_KLINE_LAST_CLOSE_DATA: 'UPDATE_NEW_KLINE_LAST_CLOSE_DATA',
  UPDATE_NEW_TICKER: 'UPDATE_NEW_TICKER',
  UPDATE_SEL_TICKER: 'UPDATE_SEL_TICKER',
  UPDATE_SYSTEM_TIME: 'UPDATE_SYSTEM_TIME',
  UPDATE_NEW_DEPTH: 'UPDATE_NEW_DEPTH',
  UPDATE_NEW_HISTORY: 'UPDATE_NEW_HISTORY',
  UPDATE_USER_ASSETS: 'UPDATE_USER_ASSETS',
  UPDATE_SEL_USER_ASSETS: 'UPDATE_SEL_USER_ASSETS',
  UPDATE_USER_ASSETS_All: 'UPDATE_USER_ASSETS_All',
  UPDATE_CURRENT_PLANS_ALL: 'UPDATE_CURRENT_PLANS_ALL',
  UPDATE_CURRENT_ENTRUSTS_ALL: 'UPDATE_CURRENT_ENTRUSTS_ALL',
  UPDATE_LIST_NEW: 'UPDATE_LIST_NEW',
  UPDATE_NEW_POSITION: 'UPDATE_NEW_POSITION',
  UPDATE_NEW_POSITION_ALL: 'UPDATE_NEW_POSITION_ALL',
  UPDATE_FIXED_POSITION: 'UPDATE_FIXED_POSITION',
  UPDATE_SEL_POSITION: 'UPDATE_SEL_POSITION',
  UPDATE_ADL: 'UPDATE_ADL',
  UPDATE_ADL_ALL: 'UPDATE_ADL_ALL',
  UPDATE_LEVERAGE_DIALOG: 'UPDATE_LEVERAGE_DIALOG',
  UPDATE_SEL_LEVERAGE_DIALOG: 'UPDATE_SEL_LEVERAGE_DIALOG',
  UPDATE_NEW_PROTOCOL_DIALOG: 'UPDATE_NEW_PROTOCOL_DIALOG',
  UPDATE_CHECK_LEVER: 'UPDATE_CHECK_LEVER',
  UPDATE_USER_SELECT_DEPTH: 'UPDATE_USER_SELECT_DEPTH',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA',
  CLEAR_NEW_BASE_STATE: 'CLEAR_NEW_BASE_STATE',
  INIT_NEW_BASE_STATE: 'INIT_NEW_BASE_STATE',
  UPDATE_USER_CONFIG: 'UPDATE_USER_CONFIG',
  UPDATE_NEW_DEPTH_SUM: 'UPDATE_NEW_DEPTH_SUM',
  SET_TRADE_LEVER: 'SET_TRADE_LEVER',
  SET_UNIT_TYPE: 'SET_UNIT_TYPE',
  UPDATE_REVERSE_CHECKED: 'UPDATE_REVERSE_CHECKED',
  UPDATE_CONTRACT_PRICE: 'UPDATE_CONTRACT_PRICE',
  NEW_UPDATE_METADATA: 'NEW_UPDATE_METADATA',
  FETCH_CONTRACT_LIST: 'FETCH_CONTRACT_LIST',
  SET_NEW_USERINFO: 'SET_NEW_USERINFO',
  SET_NEW_ASSETSINFO: 'SET_NEW_ASSETSINFO',
  UPDATE_MAX_OPEN_SIZE: 'UPDATE_MAX_OPEN_SIZE',
  UPDATE_CURRENT_CONTRACT_ID: 'UPDATE_CURRENT_CONTRACT_ID',
  UPDATE_NEW_LONGLEVERAGE: 'UPDATE_NEW_LONGLEVERAGE',
  UPDATE_NEW_SHORTLEVERAGE: 'UPDATE_NEW_SHORTLEVERAGE',
  UPDATE_CURRENT_CONTRACT_MODE: 'UPDATE_CURRENT_CONTRACT_MODE',
  SET_BASE_COIN_NAME: 'SET_BASE_COIN_NAME',
  SET_QUOTE_COIN_NAME: 'SET_QUOTE_COIN_NAME',
  SET_NEW_COIN_LIST: 'SET_NEW_COIN_LIST',
  UPDATE_NEW_OPEN_COST: 'UPDATE_NEW_OPEN_COST',
  UPDATE_FUNDING_RATE: 'UPDATE_FUNDING_RATE',
  UPDATE_NEW_ALL_TICKER: 'UPDATE_NEW_ALL_TICKER',
  UPDATE_NEW_KLINES: 'UPDATE_NEW_KLINES',
  SET_NEW_ORDERPRICEOBJ: 'SET_NEW_ORDERPRICEOBJ',
  UPDATE_NEW_BONUS: 'UPDATE_NEW_BONUS',
  SET_WS_SPEED: 'SET_WS_SPEED',
  SET_CONTRACT_ENABLE_DISPLAY: 'SET_CONTRACT_ENABLE_DISPLAY',
  SET_SHAREPOSTERS: 'SET_SHAREPOSTERS'
};

/**
 * actions类型常量
 */
export const ACTION_TYPES = {
  NEW_INIT_ALL: 'NEW_INIT_ALL',
  INIT_CONTRACT_LEVERAGE_LIST: 'INIT_CONTRACT_LEVERAGE_LIST',
  UPDATE_NEW_POSITION: 'UPDATE_NEW_POSITION',
  CALCULATE_NEW_POSITION: 'CALCULATE_NEW_POSITION',
  UPDATE_NEW_POSITION_ALL: 'UPDATE_NEW_POSITION_ALL',
  CHECKED_NEW_POSITION_ALL: 'CHECKED_NEW_POSITION_ALL',
  UPDATE_NEW_HISTORY: 'UPDATE_NEW_HISTORY',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA',
  UPDATE_NEW_ASSETS: 'UPDATE_NEW_ASSETS',
  UPDATE_NEW_ASSETS_ALL: 'UPDATE_NEW_ASSETS_ALL',
  UPDATE_USER_CONFIG: 'UPDATE_USER_CONFIG',
  UPDATE_NEW_DEPTH: 'UPDATE_NEW_DEPTH',
  SET_NEW_DEPTH:'SET_NEW_DEPTH',
  NEW_LAZY_MAKER_PLACE: 'NEW_LAZY_MAKER_PLACE',
  NEW_LAZY_TAKER_PLACE: 'NEW_LAZY_TAKER_PLACE',
  NEW_UPDATE_METADATA: 'NEW_UPDATE_METADATA',
  FETCH_CONTRACT_LIST: 'FETCH_CONTRACT_LIST',
  SET_NEW_ASSETSINFO: 'SET_NEW_ASSETSINFO',
  SET_NEW_USERINFO: 'SET_NEW_USERINFO',
  UPDATE_HISTORY_ENTRUSTS: 'UPDATE_HISTORY_ENTRUSTS',
  UPDATE_HISTORY_PLANS: 'UPDATE_HISTORY_PLANS',
  UPDATE_FUNDING_RATE: 'UPDATE_FUNDING_RATE',
  UPDATE_NEW_ALL_TICKER: 'UPDATE_NEW_ALL_TICKER',
  UPDATE_NEW_TICKER: 'UPDATE_NEW_TICKER',
  UPDATE_ENTRUST_DETAIL: 'UPDATE_ENTRUST_DETAIL',
  UPDATE_FINANCIAL_RECORD: 'UPDATE_FINANCIAL_RECORD',
  FETCH_CONTRACT_ENABLE_DISPLAY: 'FETCH_CONTRACT_ENABLE_DISPLAY',
  FETCH_SHAREPOSTERS: 'FETCH_SHAREPOSTERS'
};

/**
 * 创建默认状态树
 */
export function createDefaultStore(): IState {
  return {
    layout: SUPPORTED_LAYOUT[2],
    stateNumber: 0,
    contractList: [],
    newKlineLastCloseData: {
      price: 0,
      prevPrice: 0
    },
    selTicker: createDefaultTicker(),
    productCode: '-/-',
    selProductCode: '',
    contractInfo: createDefaultContractInfo(),
    selContractInfo: createDefaultContractInfo(),
    contractPlaceInfo: createDefaultContractPlaceInfo(),
    positionLeverageLevel: [],
    positionLeverageLevelAll: [],
    systemTime: 0,
    asks: [],
    bids: [],
    history: [],
    assets: createDefaultAssetsInfo(),
    selAssets: createDefaultAssetsInfo(),
    assetsAll: [],
    currentEntrust: [],
    currentEntrustsAll: [],
    currentPlans: [],
    currentPlansAll: [],
    historyPlans: [],
    entrustDetail: [],
    historyEntrusts: [],
    traceOrders: [],
    positionHistory: [],
    financialRecords: [],
    position: [],
    positionAll: [],
    longPositionInfo: createDefaultPositionInfo(1),
    shortPositionInfo: createDefaultPositionInfo(2),
    selLongPositionInfo: createDefaultPositionInfo(1),
    selShortPositionInfo: createDefaultPositionInfo(2),
    longADLQueue: [],
    shortADLQueue: [],
    ADLQueueAll: [],
    typeLeverageDialog: 1, // 默认多仓
    showLeverageDialog: false,
    contractIdLeverageDialog: 10000001,
    selTypeLeverageDialog: 1, // 默认多仓
    selShowLeverageDialog: false,
    showContractProtocol: false,
    showCheckLever: false,
    userSelectDepth: '',
    userSelectInfo: {
      price: '',
      amount: ''
    },
    userConfig: createDefaultUserConfig(),
    asksTotal: 1, // 不至于算比例得到NaN 1/(1+1)*100%=50%
    bidsTotal: 1,
    tradeLever: -1,
    unitType: 1, // 0-一般的张数单位 1-币量单位 2-USDT单位
    layoutReady: true,
    reversePositionChecked: true,
    userInfo: {},
    orderPriceObj: {
      longPrice: '',
      shortPrice: ''
    },
    assetsInfoAll: {},
    longLeverage: 20,
    shortLeverage: 20,
    baseCoinName: 'BTC',
    quoteCoinName: 'USDT',
    fundingRateInfo: {},
    tickerAll: {},
    newAllTicker: {},
    ticker: createDefaultTicker(),
    currentContractMode: {
      marginMode: 'SHARED', // SHARED, CROSS, ISOLATED
      positionMode: '', // HEDGE, ONE_WAY
      separatedMode: ''
    },
    openCost: {
      openLongCostSize: 0,
      openShortCostSize: 0
    },
    currentContractId: '',
    wsSpeed: 9999,
    contractEnableDisplay: {}, // 合约开启显示列表
    bonus: 0, //合約Pro 體驗金
    sharePosters: [] // 分享海报
  };
}

export function createDefaultCoinList(): CoinInfoType[] {
  return [
    {
      coinId: '1',
      coinName: 'BTC',
      stepSize: '0.001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '2',
      coinName: 'USDT',
      stepSize: '0.000001',
      iconUrl: '',
      enableCollateral: true
    },
    {
      coinId: '3',
      coinName: 'ETH',
      stepSize: '0.01',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '4',
      coinName: 'BGB',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '5',
      coinName: 'LTC',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '6',
      coinName: 'BCH',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '9',
      coinName: 'ETC',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '45',
      coinName: 'TRX',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '61',
      coinName: 'EOS',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '64',
      coinName: 'SUSDT',
      stepSize: '0.000001',
      iconUrl: '',
      enableCollateral: true
    },
    {
      coinId: '82',
      coinName: 'DOGE',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '83',
      coinName: 'XRP',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '84',
      coinName: 'SOL',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '85',
      coinName: 'BNB',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '86',
      coinName: 'ARB',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '87',
      coinName: 'MATIC',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '88',
      coinName: 'ADA',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '89',
      coinName: 'RNDR',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '90',
      coinName: 'SHIB',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '91',
      coinName: 'OP',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '92',
      coinName: 'USDC',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '93',
      coinName: 'PEPE',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '94',
      coinName: 'LINA',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '95',
      coinName: 'AAVE',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '96',
      coinName: 'APE',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '97',
      coinName: 'APT',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    },
    {
      coinId: '98',
      coinName: 'CFX',
      stepSize: '0.0001',
      iconUrl: '',
      enableCollateral: false
    }
  ];
}

export function createDefaultContractInfo(): ContractInfo {
  return {
    contractId: '10000001',
    contractName: 'BTC/USDT',
    baseCoinId: '1',
    quoteCoinId: '2',
    tickSize: '0.1',
    stepSize: '0.001',
    minOrderSize: '0.001',
    maxOrderSize: '100',
    maxPositionSize: '130',
    riskLimitTierList: [
      {
        tier: 1,
        startPositionValue: '0',
        endPositionValue: '30000',
        maxLeverage: '200',
        maintenanceMarginRate: '0.003'
      },
      {
        tier: 2,
        startPositionValue: '30001',
        endPositionValue: '65000',
        maxLeverage: '125',
        maintenanceMarginRate: '0.004'
      },
      {
        tier: 3,
        startPositionValue: '65001',
        endPositionValue: '90000',
        maxLeverage: '100',
        maintenanceMarginRate: '0.005'
      },
      {
        tier: 4,
        startPositionValue: '90001',
        endPositionValue: '115000',
        maxLeverage: '50',
        maintenanceMarginRate: '0.01'
      },
      {
        tier: 5,
        startPositionValue: '115001',
        endPositionValue: '187500',
        maxLeverage: '20',
        maintenanceMarginRate: '0.025'
      },
      {
        tier: 6,
        startPositionValue: '187501',
        endPositionValue: '1000000',
        maxLeverage: '10',
        maintenanceMarginRate: '0.05'
      },
      {
        tier: 7,
        startPositionValue: '1000001',
        endPositionValue: '2500000',
        maxLeverage: '5',
        maintenanceMarginRate: '0.1'
      },
      {
        tier: 8,
        startPositionValue: '2500000',
        endPositionValue: '5000000',
        maxLeverage: '4',
        maintenanceMarginRate: '0.125'
      },
      {
        tier: 9,
        startPositionValue: '5000001',
        endPositionValue: '10000000',
        maxLeverage: '3',
        maintenanceMarginRate: '0.15'
      },
      {
        tier: 10,
        startPositionValue: '10000001',
        endPositionValue: '15000000',
        maxLeverage: '2',
        maintenanceMarginRate: '0.25'
      },
      {
        tier: 11,
        startPositionValue: '15000001',
        endPositionValue: '1000000000',
        maxLeverage: '1',
        maintenanceMarginRate: '0.5'
      }
    ],
    frozenMarginFloatingRatio: '0.01',
    frozenFillFeeFloatingRatio: '0.005',
    takerFeeRate: '0.0003',
    makerFeeRate: '0.0002',
    liquidateFeeRate: '0.01',
    defaultLeverage: '20',
    buyLimitPriceRatio: '0.1',
    sellLimitPriceRatio: '0.1',
    longLimitLiquidatePrice: '0.5',
    shortLimitLiquidatePrice: '1000000',
    marketOpenLimitSize: '50',
    marketCloseLimitSize: '50',
    enableTrade: true,
    enableDisplay: true,
    enableOpenPosition: true,
    supportMarginModeList: ['SHARED', 'ISOLATED'],
    supportSeparatedModeList: ['COMBINED'],
    supportPositionModeList: ['HEDGE'],
    fundingInterestRate: '0.0003',
    fundingImpactMarginNotional: '10',
    fundingMaxRate: '0.000234',
    fundingMinRate: '-0.000234',
    fundingDailySettleTime: ['07:00:00', '15:00:00', '23:00:00'],
    displayDigitMerge: '0.5,1,2,5,10',
    displayMaxLeverage: '200',
    displayMinLeverage: '1',
    displayWebLeverageSettingList: ['1', '2', '3', '5', '10', '20', '125', '200'],
    displayAppLeverageSettingList: ['2', '3', '5', '10', '20', '25', '50', '125', '200'],
    displayNew: false,
    displayHot: true,
    extraConfig: {
      productCode: 'cmt_btcusdt',
      iconUrl:
        'https://d2tfhb4luz96yb.cloudfront.net/otc/images/contract_coin_img/d99bca657face47f8c24e2f5c7ef983e.png',
      preValue: '0.001',
      forwardContractFlag: 'true',
      status: '1',
      indexDataSource: 'binance, okex, huobi',
      simulation: 'false',
      contractSort: '10001'
    },
    placeConfig: {
      serverCalPlace: '12',
      delegatePricePlace: '4',
      volumePlace: '0',
      pricePlace: '4',
      capitalRatePlace: '8',
      transferInOutPlace: '8',
      propertyPlace: '4',
      marginRatePlace: '4',
      propertyHighPlace: '8',
      baseCoinPlace: '1'
    },
    priceEndStep:5,
    priceUseFeeRate: false,
    supportTracing: true,
    supportPlanMarket: true
  };
}

export function createDefaultContractPlaceInfo(): ContractPlaceInfo {
  return {
    serverCalPlace: 12, // 服务器计算用精度
    delegatePricePlace: 4, // 委托价格精度
    volumePlace: 0, // 显示用数量精度
    pricePlace: 4, // 显示用一般价格精度
    capitalRatePlace: 8, // 资金费率精度
    transferInOutPlace: 8, // 转入转出精度
    propertyPlace: 4, // 显示用资产精度
    marginRatePlace: 4, // 保证金率精度
    propertyHighPlace: 8, // 高精度显示资产
    baseCoinPlace: 1 // 张数转币量所用精度
  };
}

export function createDefaultTicker(): TickerInfo {
  return {
    contractId: '',
    priceChange: '0',
    priceChangePercent: '0',
    trades: '0',
    size: '0',
    value: '0',
    high: '0',
    low: '0',
    open: '0',
    close: '0',
    highTime: '0',
    lowTime: '0',
    startTime: '0',
    endTime: '0',
    lastPrice: '0',
    markPrice: '0'
  };
}

export function createDefaultAssetsInfo(): UserAssetsInfo {
  return {
    balance: '0', // 可用
    balanceText: '0',
    cashgift: '0', // 体验金
    used: '0', // 已用
    longLeverage: '20', // 多仓杠杆
    shortLeverage: '20', // 空仓杠杆
    freeze: '0',
    longAccountAchievedProfits: '0', // 多仓已实现盈亏
    shortAccountAchievedProfits: '0', // 空仓已实现盈亏
    longAccountDelegateCount: '0', // 多仓开仓委托张数
    shortAccountDelegateCount: '0',
    holdModeEnum: 'CROSS_MARGIN', // 全仓,逐仓
    subHoldModeEnum: 'MERGED', // 分合仓
    isFixedMode: false, // true-逐仓模式
    isSplitMode: false, // true-分仓模式
    dTotalUpnl: null,
    dTotalUsed: null,
    dTotalProfits: null,
    crossMarginRateText: '-',
    extraMM: '0'
  };
}

export function createDefaultPositionInfo(positionType: number): FixedPositionInfo {
  return {
    amount: '0', // 持仓数量
    autoAppendMargin: 0, // 是否开启自动追加保证金， 全仓将不具备这个字段
    delegateCount: '0', // 委托张数
    freezeCount: '0', // 平仓冻结张数
    margin: '0', // 保证金量
    openPrice: '0', // 开仓均价
    pnl: '0', // 已实现盈亏
    position: positionType, // 1多仓 2空仓
    reducePrice: '0', // 预估强平价
    holdCount: 0,
    dUpnl: null, //
    dValue: null,
    dCurrentValue: null,
    dRoe: null,
    dMarginRate: null,
    adlQueueIndex: -1
  };
}

/**
 * 默认的用户配置
 * @return {{alertForLazyOp: boolean, alertForProfitControl: boolean}}
 */
export function createDefaultUserConfig(): UserConfig {
  return {
    agreedTime: -1,
    alertForLazyOp: true,
    alertForProfitControl: true,
    switchForLazy: false,
    directionForLazy: 1,
    checkForMakerPO: true, // 挂单是否使用只做Maker模式
    checkForTakerIOC: true, // 吃单是否使用IOC
    checkForTakerFOK: false, // 吃单是否使用FOK
    checkForOpenMode: 1, // 开仓数量模式 1-百分比 2-盘口
    openPercent: 100, // 开仓数量百分比模式具体百分比
    checkForCloseMode: 1, // 平仓数量模式 1-百分比 2-盘口
    closePercent: 100, // 平仓数量百分比模式具体百分比
    isReady: false
  };
}
