import { TRACE_MUTATIONS_TYPE_MIX, createTraceUserInfoMix } from './types'

export default {
  /**
   *  增量处理ticker数据
   */
  [TRACE_MUTATIONS_TYPE_MIX.UPDATE_ALLTICKER_MIX](state, val) {
    if (state.allTickerMix.length <= 0) {
      state.allTickerMix = [...val];
    } else {
      let old = [...state.allTickerMix];
      old.forEach(contract => {
        let match = val.find(item => item.symbolCode === contract.symbolCode);
        if (match) {
          Object.assign(contract, match);
        }
      });
      state.allTickerMix = [...old];
    }
  },

  /**
   * 设置跟单用户基本信息
   */
  [TRACE_MUTATIONS_TYPE_MIX.SET_TRACE_USETINFO_MIX]: (state, info) => {
    if (info) {
      state.traceUserInfoMix = Object.assign({}, state.traceUserInfoMix, info);
    } else {
      state.traceUserInfoMix = createTraceUserInfoMix();
    }
  },

  /***
   * 设置 当前跟单 带单数据 - 混合
   */
  [TRACE_MUTATIONS_TYPE_MIX.SET_TRACE_ORDERS_MIX]: (state, { productCode, data = [] }) => {
    state.allTraceOrdersMix = data;
  },

  /***
   * 设置 当前跟单 带单数据 - 专业
   */
  [TRACE_MUTATIONS_TYPE_MIX.SET_TRACE_ORDER_SPECIAL]: (state, { productCode, data = [] }) => {
    state.allTraceOrdersSpecial = data;
  },

  /**
   * 更新跟单数据准备状态
   * @param {*} state
   * @param {*} flag
   */
  [TRACE_MUTATIONS_TYPE_MIX.UPDATA_TRACEDATASTATUS_MIX](state, flag) {
    state.traceDataReadyMix = flag;
  }
}
