import { TRACE_GETTERS_TYPE_MIX } from './types'

export default {
  /**
   * 根据productCode 来获取ticker 信息
   */
  [TRACE_GETTERS_TYPE_MIX.GET_PRODUCT_TICK_MIX]: (state) => (productCode, businessLine, tokenId) => {
    let allTicker = state.allTickerMix || [];
    let tmp = allTicker.find(item => {
      return item.symbolCode === productCode && item.businessLine === businessLine;
    });
    let productCodeTickInfo = {
      price: 1, // 当前价
      markPrice: 1, // 合理标记价格
      tokenMarkPrice: 1, // 保证金的合理标记价格
    };
    if(tmp) {
      productCodeTickInfo = {
        price: tmp.price,
        markPrice: tmp.markPrice,
        tokenMarkPrice: 1,
      };
    }
    let tmp2 = allTicker.find(item => {
      return item.baseSymbol === tokenId && item.businessLine === businessLine;
    });
    if(tmp2) {
      productCodeTickInfo.tokenMarkPrice = tmp2.markPrice; // 保证金的合理标记价格
    }
    return productCodeTickInfo;
  },

  // 混合 + 专业 订单列表
  [TRACE_GETTERS_TYPE_MIX.GET_WS_ALL_ORDERS]: state => {
    let mixArr = state.allTraceOrdersMix || [];
    let spacialArr =  state.allTraceOrdersSpecial || [];
    let allArr = mixArr.concat(spacialArr);
    allArr = allArr.sort((a, b) => {
      b.openTime - a.openTime;
    });
    return allArr || [];
  }
}
