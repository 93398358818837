// @flow
import type {IState} from '~/store/spot/types';

export const SPOT_GETTERS_TYPE = {
  SYMBOL_INFO: 'symbolInfo',
  CURRENT_ENTRUST_PRICE_ARRAY: 'currentEntrustPriceArray',
  TRADE_INFO: 'tradeInfo',
};

export default {
  /**
   * 获取交易对信息-
   * @param state
   * @param getters
   * @param rootState
   * @returns {{exchangeId: (number|*), leftCoinName: (*|string), symbol: *, rightCoinId: *, leftCoinId: *, rightCoinName: (*|string)}}
   */
  [SPOT_GETTERS_TYPE.SYMBOL_INFO](state, getters, rootState) {
    let leftCoinName = (state.symbol.split('_')[0] || '').toUpperCase(),
      rightCoinName = (state.symbol.split('_')[1] || '').toUpperCase(),
      leftCoinId, rightCoinId;
    for (let coin of rootState.leftCoinList) {
      if ((coin.coinName || '').toUpperCase() === leftCoinName) {
        leftCoinId = coin.coinId;
        break;
      }
    }

    for (let coin of rootState.rightCoinList) {
      if ((coin.coinName || '').toUpperCase() === rightCoinName) {
        rightCoinId = coin.coinId;
        break;
      }
    }
    return {
      symbolId: leftCoinName + rightCoinName + "_SPBL",
      symbolCode: leftCoinName + rightCoinName,
      symbol: state.symbol,
      exchangeId: state.symbol.exchangeId,
      leftCoinName,
      rightCoinName,
      leftCoinId,
      rightCoinId,
    };
  },

  /**
   * 获取当前交易对自己的买卖深度价格列表
   * @param state
   * @returns {{buy: Array, sell: Array}}
   */
  [SPOT_GETTERS_TYPE.CURRENT_ENTRUST_PRICE_ARRAY](state: IState) {
    // 要去区分买卖深度价格数组t
    let list = state.currentEntrust,
      tempBuyArray = [],
      tempSellArray = [];

    for (let entrust of list) {
      if (entrust.ftype === 0) {
        tempBuyArray.push(entrust.fprize);
      }
      if (entrust.ftype === 1) {
        tempSellArray.push(entrust.fprize);
      }
    }
    return {buy: tempBuyArray, sell: tempSellArray};
  },

  /**
   * 获取指定交易对的市场行情
   * @param state
   * @returns {*}
   */
  [SPOT_GETTERS_TYPE.TRADE_INFO](state: IState) {
    let allTicker = state.allTicker;
    for (let ticker of allTicker) {
      if (state.symbol === ticker.leftCoinName + '_' + ticker.rightCoinName) {
        return ticker;
      }
    }
  },
};
