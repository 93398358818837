import createState from '~/store/spot/types';
import mutations, {SPOT_MUTATIONS_TYPE} from '~/store/spot/mutations';
import actions, {SPOT_ACTIONS_TYPE} from '~/store/spot/actions';
import getters, {SPOT_GETTERS_TYPE} from '~/store/spot/getters';

export {
  SPOT_MUTATIONS_TYPE, SPOT_GETTERS_TYPE, SPOT_ACTIONS_TYPE,
};

export default {
  namespaced: true,
  state: createState,
  getters,
  mutations,
  actions,
};
