// @flow

type IMarketDetail = {
  last: number,
  change: number,
  vol: number,
  high: number,
  low: number,
  pricePlace: number,
  toCNY: number,  // 注意，这里的toCNY是指交易对中右币与USDT的比例
  exchangeId: number,
  leverageRatio: number,
  supportLever: boolean
}

/**
 * 包含挂单吃单选项在内的用户设置
 */
export type UserConfig = {
  agreedTime: number, // 同意使用的时间
  alertForLazyOp: number, // 挂单吃单是否提示（现在是两小时内不再提醒）
  alertForProfitControl: boolean, // 止盈止损是否提示
  switchForLazy: boolean, // 是否使用吃单挂单
  directionForLazy: number, // 挂单吃单的模式 1-开仓 2-平仓
  checkForMakerPO: boolean, // 挂单是否使用"只做Maker"模式
  checkForTakerIOC: boolean, // 吃单是否使用"IOC"
  checkForTakerFOK: boolean, // 吃单是否使用"FOK"
  checkForOpenMode: number, // 开仓数量模式 1-百分比 2-盘口
  openPercent: number, // 开仓数量百分比模式具体百分比
  checkForCloseMode: number, // 平仓数量模式 1-百分比 2-盘口
  closePercent: number, // 平仓数量百分比模式具体百分比
  isReady: boolean // 配置是否就绪
};

export type IState = {
  symbol: string,
  depthPrice: string,
  buyDepthPrice: string,
  sellDepthPrice: string,
  marketDetail: IMarketDetail,
  leftCoinCount: string,
  rightCoinCount: string,
  currentEntrust: [],
  fentrustlogs: [],
  historyEntrust: [],
  entrustLoading: boolean,
  marketDetailReady: boolean,
  allTicker: [], // 所有的交易数据
  burstPrice: string,
  riskRate: number,
  switchValue: boolean, // 只是同步开关的值，实际是否开启需要延迟同步
  layout: string,
  userConfig: UserConfig, // 用户配置保存在localStorage中
}

export default function createState(): IState {
  return {
    symbol: '_',
    depthPrice: '',
    buyDepthPrice: '',
    sellDepthPrice: '',
    marketDetail: {
      last: 0,
      change: 0,
      vol: 0,
      high: 0,
      low: 0,
      pricePlace: 0,
      volPlace: 4, // 数量精度
      toCNY: 1,  // 注意，这里的toCNY是指交易对中右币与USDT的比例
      exchangeId: 0,
      leverageRatio: 0,
      supportLever: false,
    },
    leftCoinCount: Number(0).toFixed(8),
    rightCoinCount: Number(0).toFixed(8),
    currentEntrust: [],
    fentrustlogs: [],
    historyEntrust: [],
    entrustLoading: true,
    marketDetailReady: false,
    allTicker: [], // 所有的交易数据
    burstPrice: '-',
    riskRate: 0,
    switchValue: false, // 只是同步开关的值，实际是否开启需要延迟同步
    layout: 'center',
    userConfig: createDefaultUserConfig(),
  };
}

/**
 * 默认的用户配置
 * @return {{alertForLazyOp: boolean, alertForProfitControl: boolean}}
 */
export function createDefaultUserConfig(): UserConfig {
  return {
    agreedTime: -1,
    alertForLazyOp: 0,
    alertForProfitControl: true,
    switchForLazy: false,
    directionForLazy: 1,
    checkForMakerPO: true, // 挂单是否使用"只做Maker"模式
    checkForTakerIOC: true, // 吃单是否使用"IOC"
    checkForTakerFOK: false, // 吃单是否使用"FOK"
    checkForOpenMode: 1, // 开仓数量模式 1-百分比 2-盘口
    openPercent: 100, // 开仓数量百分比模式具体百分比
    checkForCloseMode: 1, // 平仓数量模式 1-百分比 2-盘口
    closePercent: 100, // 平仓数量百分比模式具体百分比
    isReady: false,
  };
}
