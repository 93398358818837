import {TRACE_PRO_MUTATIONS_TYPE, createState} from './types'
import mutations from './mutations'
import getters from './getters'

let state = () => {
  return createState();
}


export default {
  namespaced: true,
  state,
  TRACE_PRO_MUTATIONS_TYPE,
  getters,
  mutations
}
