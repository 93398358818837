// @flow

import type { PositionInfo } from '~/store/newTrading/types';
import { Decimal } from 'decimal.js';
import {
  getProfits,
  calValue,
  calMarginRate,
  calBankruptcyPrice,
  calPerLeverProfit
} from '~/components/swapnew/src/Calculator';

/**
 * 判断仓位是否没有实际变化
 * @param positionA
 * @param positionB
 */
export function isEqualPosition(positionA: PositionInfo, positionB: PositionInfo) {
  return (
    positionA.position === positionB.position &&
    positionA.amount === positionB.amount &&
    positionA.freezeCount === positionB.freezeCount &&
    positionA.margin === positionB.margin
  );
}

/**
 * 判断是否是空仓
 * @param positionInfo
 * @return {boolean}
 */
export function isEmptyPosition(positionInfo: PositionInfo) {
  return Number(positionInfo.amount) + Number(positionInfo.freezeCount) <= 0;
}

/**
 * 格式化仓位信息
 * @param newPositionInfo
 * @param contractInfo
 * @param ticker
 * @param assets
 * @param leverage
 * @param queue
 */
export function formatPositionData(newPositionInfo, contractInfo, ticker, assets, leverage, queue) {
  // 附加总仓位大小
  //debugger
  newPositionInfo.holdCount = Number(newPositionInfo.amount) + Number(newPositionInfo.freezeCount);
  //debugger
  // 基本元素
  let dHoldCount = new Decimal(newPositionInfo.holdCount);
  let dMargin = new Decimal(newPositionInfo.margin);
  let dFairPrice = new Decimal(ticker.fairPrice),
    dOpenPrice = new Decimal(newPositionInfo.openPrice);

  // 未实现盈亏
  let upnl = getProfits(
    newPositionInfo.position,
    dHoldCount,
    dFairPrice,
    dOpenPrice,
    !contractInfo.forwardContractFlag
  );
  newPositionInfo.dUpnl = upnl;
  // 合理标记价格-仓位价值
  let markPriceValue = calValue(
    dHoldCount,
    dFairPrice,
    !contractInfo.forwardContractFlag
  );
  // 开仓价仓位价值
  let openPriceValue = calValue(
    dHoldCount,
    dOpenPrice,
    !contractInfo.forwardContractFlag
  );
  newPositionInfo.dValue = openPriceValue;
  newPositionInfo.dCurrentValue = markPriceValue;
  // 保证金率
  // 20200818 扣除手续费部分，让保证金率更加危险，爆仓要收手续费
  newPositionInfo.dMarginRate = calMarginRate(
    dHoldCount,
    dMargin,
    dFairPrice,
    upnl,
    !contractInfo.forwardContractFlag
  )
    .sub(contractInfo.feeRate)
    .times(100);

  // 破产价
  let bankruptPrice = calBankruptcyPrice(
    newPositionInfo.position,
    dHoldCount,
    dOpenPrice,
    dMargin,
    !contractInfo.forwardContractFlag
  );
  // 破产价仓位价值
  let bankruptValue = calValue(
    dHoldCount,
    bankruptPrice,
    !contractInfo.forwardContractFlag
  );

  // 单位杠杆净盈利
  let validProfitsIndex = calPerLeverProfit(
    newPositionInfo.position,
    markPriceValue,
    openPriceValue,
    bankruptValue
  ).toNumber();

  let count = 1;
  queue.find((adlInfo, index) => {
    let flag;
    if (index === 0) {
      flag = validProfitsIndex <= adlInfo[1];
    } else if (index === queue.length - 1) {
      flag = validProfitsIndex >= adlInfo[0];
    } else {
      flag = validProfitsIndex >= adlInfo[0] && validProfitsIndex <= adlInfo[1];
    }

    if (flag) {
      count = index + 1;
    }
    return flag;
  });

  newPositionInfo.adlQueueIndex = count;

  // 回报率 未实现盈亏/应当占用保证金
  // 逐仓：应当占用保证金=开仓价值/杠杆
  // 全仓：保证金在总账户里，所以使用后端算好的值
  newPositionInfo.dRoe = upnl
    .div(assets.isFixedMode ? openPriceValue.div(leverage) : dMargin)
    .times(100);
  return newPositionInfo;
}
