import { TRACE_MUTATIONS_TYPE, createTraceUserInfo } from './types'

export default {
    /**
     *  增量处理ticker数据
     */
    [TRACE_MUTATIONS_TYPE.UPDATE_ALLTICKER](state,val){
        if (state.allTicker.length <= 0) {
             state.allTicker = [...val];
         } else {
               let old = [...state.allTicker];
               old.forEach(contract => {
                  let match = val.find(item => item.productCode === contract.productCode);
                  if (match) {
                     Object.assign(contract, match);
                  }
               });
               state.allTicker = [...old];
         }
    },
    /**
     * 设置跟单用户基本信息
     */
    [TRACE_MUTATIONS_TYPE.SET_TRACE_USETINFO]:(state,info)=>{
       if(info){
         state.traceUserInfo=Object.assign({},state.traceUserInfo,info);
       } else {
         state.traceUserInfo=createTraceUserInfo();
       }

    },
    /***
     * 设置 当前跟单 带单数据
     */
    [TRACE_MUTATIONS_TYPE.SET_TRACE_ORDERS]:(state,{productCode,data=[]})=>{

      if(productCode){
          //响应式数据
          state.traceOrdersProductCode = { ...state.traceOrdersProductCode,[productCode]:data };
      }
      //重新设置跟单全局数据
      let newData = [];
      Object.keys(state.traceOrdersProductCode).forEach((key)=>{
        newData.push(...state.traceOrdersProductCode[key]);
      })
      newData=newData.sort((a,b)=>{
         return   b.openTime-a.openTime;
      })
      state.allTraceOrders =newData;
    },
    /**
     * 更新跟单数据准备状态
     * @param {*} state
     * @param {*} flag
     */
    [TRACE_MUTATIONS_TYPE.UPDATA_TRACEDATASTATUS](state,flag){
      state.traceDataReady=flag;
    }
}
