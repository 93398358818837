import { createDefaultTicker } from '../future/types'

// @flow
// 跟单 trace 类型文件

/**
 * 当前跟单和当前单带实体类似
 */
export type TraceOrderInfo = {
  tradeUserId: string, // 带单者ID，有这个字段表示为跟单类型
  traderName: string, // 带单者昵称
  closeFillSize: string, // 平仓张数
  id: string, // 订单号
  contractId: string, // 合约名称
  position: string, // 仓位方向 多1空0
  openLeverage: string, // 杠杆倍数
  openDelegateCount: string, // 开仓委托张数
  openFillSize: string, // 开仓成交张数
  averageOpenPrice: string, // 开仓均价
  openMarginAmount: string, // 累计放入保证金
  averageClosePrice: string, // 平仓均价
  realizedPnl: string, // 已实现盈亏
  stopProfitPrice: string, // 止盈价格 49%
  stopLossPrice: string, // 止损价格 - 19%
  openTime: number, // 开仓时间
  closeTime: number, // 平仓时间
  openFee: string, // 开仓累计手续费
  closeFee: string, // 平仓累计手续费
  capitalPay: string, // 资金费率支出
  capitalReceive: string, // 资金费率获取
  status: number // 0初始状态，1开仓完成状态，2平仓完成状态
};

type traceOrdersProductCode = {
  [string]: TraceOrderInfo[]
}

export type Ticker = {
  contractId: string,
  priceChange: string,
  priceChangePercent: string,
  trades: string, // 指数价格
  size: string, // 涨跌幅
  value: string, // 最高价
  high: string, // 最低价
  low: string, // 最低价
  lastPrice: string, // 成交量
  markPrice: string, // 持仓量
};

export type TraceUserInfo = {
  enProfileStatus: number, // 英文简介状态 0初始 1审核驳回 2审核中 3审核通过
  nickName: string, // 昵称
  userTypeDesc: string, //  类型说明：普通用户 1:交易员
  settledInDays: string, // 入驻天数(从他在平台注册开始)
  headMaxPic: string, // 头像大图
  settledStartTime: string, // 开始入驻时间
  userId: string, //  用户ID
  headPic: string, // 头像
  traderStatus: string, // 状态 1可用 2停用
  totalMargin: string, // 累计跟单本金-普通用户
  totalProfit: string, // 累计净利润-普通用户
  followTraders: string, // 跟随交易员人数-普通用户
  todaySplitProfit: string, // 今日分润-交易员
  yesterdaySplitProfit: string, // 昨日分润-交易员
  waitProfit: string, // 待分润
  totalSplitProfit: string, // 包含当天分润的累计分润-交易员
  sumProfit: string, // 不包含当天分润的累计分润-交易员
  userType: 0 | 1, // 0:普通用户 1:交易员
  isNewTips: false, // 是否有新的跟单提醒
}

export function createTraceUserInfo(): TraceUserInfo {
  return {
    enProfileStatus: 0,
    totalProfit: '',
    nickName: '',
    userTypeDesc: '',
    settledInDays: '',
    headMaxPic: '',
    settledStartTime: '',
    userId: '',
    headPic: '', // 头像
    traderStatus: '',
    totalMargin: '',
    todaySplitProfit: '',
    followTraders: '',
    yesterdaySplitProfit: '',
    waitProfit: '',
    totalSplitProfit: '',
    sumProfit: '',
    userType: -1,
    isNewTips: false
  }
}

export type IState = {
  allTraceOrders: TraceOrderInfo[],
  traceDataReady:Boolean, // 跟单带单数据准备状态 用在个人中心页面 获取数据 及分发数据不在一个组件
  traceOrdersProductCode: traceOrdersProductCode,
  traceUserInfo: TraceUserInfo,
  ticker: Ticker,
  allTicker: Ticker[]
}

/**
 * 生成默认的state
 */
export function createState(): IState {
  return {
    allTraceOrders: [],
    traceDataReady: false,
    traceOrdersProductCode: {},
    traceUserInfo: createTraceUserInfo(),
    allTicker: [],
    ticker: createDefaultTicker(),
    isOk: true
  }
}

export const TRACE_PRO_MUTATIONS_TYPE = {
  // 设置跟单用户信息
  SET_TRACE_USETINFO: 'set_trace_user_info_pro',
  // 设置 当前跟单 当前带单数据
  SET_TRACE_ORDERS: 'set_trace_orders_pro',
  // 更新 当前跟单 当前带单数据
  EDIT_TRACE_ORDERS: 'edit_trace_orders_pro',
  // 清空跟单数据
  CLEAR_TRACE_ORDERS: 'clear_trace_orders_pro',
  // 更新跟单数据状态
  UPDATA_TRACEDATASTATUS: 'update_tracedata_status_pro'
}

export const TRACE_PRO_GETTERS_TYPE = {
  // 获取当前交易对的ticker 信息
  GET_PRODUCT_TICK: 'get_productcode_tick_info_pro'
}

export const TRACE_PRO_ACTIONS_TYPE = {
  // 跟单初始化 获取
  TRACE_INIT: 'trace_init_pro',
  // 更新合约列表信息
  SET_CONTRACTLIST: 'set_contractlist_pro'
}
