import {TRACE_PRO_GETTERS_TYPE} from './types'

export default {
  /**
   * 根据productCode 来获取ticker 信息
   */
  [TRACE_PRO_GETTERS_TYPE.GET_PRODUCT_TICK]: (state) => (contractId) => {
    let allTicker = state.newTrading.newAllTicker || {};
    let tickInfo = allTicker[contractId];
    if (tickInfo) {
      return tickInfo
    } else {
      return {}
    }
  }
}
