import { TRACE_GETTERS_TYPE } from './types'

export default {
    /**
     * 根据productCode 来获取ticker 信息
     */
    [TRACE_GETTERS_TYPE.GET_PRODUCT_TICK]:(state)=>(productCode)=>{
        let allTicker = state.allTicker || [];
        let  productCodeTickInfo = allTicker.find(item=>item.productCode === productCode);
      if(productCodeTickInfo){
          return productCodeTickInfo
      } else {
          return {}
      }
    }
}