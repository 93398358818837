import {TRACE_PRO_MUTATIONS_TYPE, createTraceUserInfo} from './types'
import {TRACKING_ORDER_STATUS} from "@/config/enum.conf";

export default {
  /**
   * 设置跟单用户基本信息
   */
  [TRACE_PRO_MUTATIONS_TYPE.SET_TRACE_USETINFO]: (state, info) => {
    if (info) {
      state.traceUserInfo = Object.assign({}, state.traceUserInfo, info);
    } else {
      state.traceUserInfo = createTraceUserInfo();
    }

  },
  /***
   * 设置 当前跟单 带单数据
   */
  [TRACE_PRO_MUTATIONS_TYPE.SET_TRACE_ORDERS]: (state, data) => {
    data = data.sort((a, b) => {
      return b.openTime - a.openTime;
    })
    if (data && data.length > 0) {
      data.forEach(item => {
        item.position = item.positionSide === "LONG" ? 1 : 2; // SHORT -空，LONG-多
      })
    }
    state.allTraceOrders = data;
    state.traceDataReady = true;
  },
  /***
   * 更新 当前跟单 带单数据
   */
  [TRACE_PRO_MUTATIONS_TYPE.EDIT_TRACE_ORDERS]: (state, data) => {
    let arr = []
    if (state.isOk) {
      if (data && data.length > 0) {
        data.forEach(item => {
          if (item.status == TRACKING_ORDER_STATUS.HAVE_OPEN || item.status == TRACKING_ORDER_STATUS.FULL_CLOSE) {
            item.position = item.positionSide === "LONG" ? 1 : 2; // SHORT -空，LONG-多
            arr.push(item)
          }
        })
      }
      if (arr.length > 0) {
        state.isOk = false;
      }
      let list = [...state.allTraceOrders];
      arr.forEach(item => {
        let order = null, num = -1;
        list.forEach((info, i) => {
          if (info.id === item.id) {
            order = item;
            num = i;
          }
        })
        if (order) {
          switch (order.status) {
            case TRACKING_ORDER_STATUS.HAVE_OPEN:
              list[num] = order;
              break;
            case TRACKING_ORDER_STATUS.FULL_CLOSE:
              list.splice(num, 1);
              break;
          }
        } else if (item.status == TRACKING_ORDER_STATUS.HAVE_OPEN) { //若未匹配到，则增加
          list.unshift(item);
        }
      })
      state.allTraceOrders = list;
    }
    state.isOk = true;
  },
  /**
   * 更新跟单数据准备状态
   * @param {*} state
   * @param {*} flag
   */
  [TRACE_PRO_MUTATIONS_TYPE.UPDATA_TRACEDATASTATUS](state, flag) {
    state.traceDataReady = flag;
  }
}
