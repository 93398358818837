const WEEX_USER_LEVERAGE = 'weex::user::leverage';
const INIT_STATE = {
  symbol: '_',
  depthPrice: '',
  buyDepthPrice: '',
  sellDepthPrice: '',
  marketDetail: {
    last: 0,
    change: 0,
    vol: 0,
    high: 0,
    low: 0,
    pricePlace: 0,
    toCNY: 1, // 注意，这里的toCNY是指交易对中右币与USDT的比例
    exchangeId: 0,
    leverageRatio: 0,
    supportLever: false,
    volPlace: 4
  },
  leftCoinCount: Number(0).toFixed(8),
  rightCoinCount: Number(0).toFixed(8),
  currentEntrust: [],
  fentrustlogs: [],
  historyEntrust: [],
  entrustLoading: true,
  marketDetailReady: false,
  allTicker: [], // 所有的交易数据
  burstPrice: '-',
  riskRate: 0,
  switchValue: false // 只是同步开关的值，实际是否开启需要延迟同步
};

export const state = () => Object.assign({}, INIT_STATE);

// =========== mutations begin ===============
export const mutations = {
  setSymbol(state, newValue) {
    state.symbol = newValue;
    state.currentEntrust.length = 0;
    state.historyEntrust.length = 0;
    state.fentrustlogs.length = 0;
  },

  setDepthPrice(state, newValue) {
    state.depthPrice = newValue;
  },

  setBuyDepthPrice(state, newValue) {
    state.buyDepthPrice = newValue;
  },

  setSellDepthPrice(state, newValue) {
    state.sellDepthPrice = newValue;
  },

  changeMarketDetailReady(state, newValue) {
    if (!newValue) {
      state.entrustLoading = true;
    }
    state.marketDetailReady = newValue || false;
  },

  setMarketDetail(state, newValue) {
    // 一定要把详细数据更新后，再通知状态就绪
    Object.assign(state.marketDetail, newValue);
    state.marketDetailReady = true;
  },
  setAllTicker(state, newValue) {
    state.allTicker.length = 0;
    state.allTicker.push(...newValue);

    let allTicker = state.allTicker;
    for (let ticker of allTicker) {
      if (state.symbol === ticker.leftCoinName + '_' + ticker.rightCoinName) {
        Object.assign(state.marketDetail, ticker);
        setTimeout(() => {
          state.marketDetailReady = true;
        }, 100);
      }
    }
  },
  refreshLeftCoinCount(state, newValue) {
    state.leftCoinCount = newValue;
  },

  refreshRightCoinCount(state, newValue) {
    state.rightCoinCount = newValue;
  },

  /**
   * 清空币币交易页面的状态
   * @param state
   * @param newValue 可选的清空，否则使用默认状态
   */
  clearTradeState(state, newValue) {
    Object.assign(state, INIT_STATE);
    clearInterval(state.currentEntrustTimer);
  },

  setEntrustStore(state, payload) {
    let oldState = state[payload.type];
    if (!payload.data) {
      // 如果后台没有给定这个数据，那么将忽略该数据的更新
      // null 或者 undefined 就暂时不处理
      return;
    }
    oldState.length = 0;
    oldState.push(...payload.data);
  },

  changeEntrustLoading(state, value) {
    state.entrustLoading = value;
  },

  /**
   * 只更改开关的状态
   * @param state
   * @param newValue
   */
  changeSwitchValue(state, newValue) {
    state.switchValue = !!newValue;
  }
};
// =========== mutations end ===============

// =========== getters begin ===============
export const getters = {
  /**
   * 获取交易对信息-
   * @param state
   * @param getters
   * @param rootState
   * @returns {{exchangeId: (number|*), leftCoinName: (*|string), symbol: *, rightCoinId: *, leftCoinId: *, rightCoinName: (*|string)}}
   */
  symbolInfo(state, getters, rootState) {
    let leftCoinName = (state.symbol.split('_')[0] || '').toUpperCase();
    let rightCoinName = (state.symbol.split('_')[1] || '').toUpperCase();
    let leftCoinId;
    let rightCoinId;
    for (let coin of rootState.leftCoinList) {
      if ((coin.coinName || '').toUpperCase() === leftCoinName) {
        leftCoinId = coin.coinId;
        break;
      }
    }

    for (let coin of rootState.rightCoinList) {
      if ((coin.coinName || '').toUpperCase() === rightCoinName) {
        rightCoinId = coin.coinId;
        break;
      }
    }
    return {
      symbol: state.symbol,
      exchangeId: state.symbol.exchangeId,
      leftCoinName,
      rightCoinName,
      leftCoinId,
      rightCoinId
    };
  },

  /**
   * 获取当前交易对自己的买卖深度价格列表
   * @param state
   * @returns {{buy: Array, sell: Array}}
   */
  currentEntrustPriceArray(state) {
    // 要去区分买卖深度价格数组t
    let list = state.currentEntrust;
    let tempBuyArray = [];
    let tempSellArray = [];

    for (let entrust of list) {
      if (entrust.ftype === 0) {
        tempBuyArray.push(entrust.fprize);
      }
      if (entrust.ftype === 1) {
        tempSellArray.push(entrust.fprize);
      }
    }
    return { buy: tempBuyArray, sell: tempSellArray };
  },

  /**
   * 获取指定交易对的市场行情
   * @param state
   * @returns {*}
   */
  tradeInfo(state) {
    let allTicker = state.allTicker;
    for (let ticker of allTicker) {
      if (state.symbol === ticker.leftCoinName + '_' + ticker.rightCoinName) {
        return ticker;
      }
    }
  },

  /**
   * 获取指定交易对是否开启了杠杆
   * @param state
   * @param getters
   * @param rootState
   * @returns {function(*): boolean}
   */
  leverageSwitch: (state, getters, rootState) => {
    return false;
  }
};
// =========== getters end ===============

// =========== actions begin =============
export const actions = {
  /**
   * 刷新用户的左右币资产
   */
  refreshUserAssets({ commit, state }, currentWallets) {
    let leftCoinName = state.symbol.split('_')[0] || '';
    let rightCoinName = state.symbol.split('_')[1] || '';
    for (let coin of currentWallets) {
      let shortName = coin.fshortname || '';
      if (shortName.toUpperCase() === leftCoinName.toUpperCase()) {
        commit('refreshLeftCoinCount', Number(coin.ftotal).toFixed(8));
      }
      if (shortName.toUpperCase() === rightCoinName.toUpperCase()) {
        commit('refreshRightCoinCount', Number(coin.ftotal).toFixed(8));
      }
    }
  },

  /**
   * 更新所有的委托列表
   * @param commit
   * @param data
   */
  refreshUserEntrust({ commit }, data) {
    commit('setEntrustStore', { type: 'currentEntrust', data: data.currentEntrust });
    commit('setEntrustStore', { type: 'historyEntrust', data: data.historyEntrust });
    commit('setEntrustStore', { type: 'fentrustlogs', data: data.fentrustlogs });
    // 关闭loading
    commit('changeEntrustLoading', false);
  }
};
// =========== actions end =============
