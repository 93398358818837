export const createUserState = () => {
  return {
    antiPhishingCode: '',
    areaCode: '',
    bindEmail: false,
    bindGoogle: false,
    bindPhone: false,
    email: '',
    emailEnable: true,
    familyName: '',
    frozen: 0,
    fundDisabled: false,
    fundValue: 1,
    givenName: '',
    googleEnable: true,
    hasRealValidate: false,
    identityLevel: 0,
    isFund: false,
    loginName: '',
    mobile: '',
    nickName: '',
    openContract: false,
    openGtFees: false,
    openOtc: false,
    phone: '',
    phoneEnable: true,
    realName: '',
    starRealName: '',
    userId: '',
    // 1用户身份类型(1.普通用户2.普通商家3.高级商家)
    userType: 1,
    newDeviceFlag: false, // 是否是新设置
    canWithDraw: true // 是否可以提币
  };
};

export const createUserPreference = () => {
  return {
    backhand: 0, // 反手二次确认 0-未选择 1-选择
    contractUnitSet: 0, // 合约单位设置 0-左币 1-右币
    languageType: 0, // 语言类型 0-英语 1-中文 2-日语 3-韩语 4-越南语 5-繁体 6-俄语
    lightningOpenAndClose: 0, // 闪电开平仓二次确认 0-未选择 1-选择
    limitPriceOpenAndClose: 0, // 限价开平仓二次确认 0-未选择 1-选择
    percentageSet: 0, //
    planEntrust: 0, // 计划委托二次确认 0-未选择 1-选择
    quantityIncludesDelegated: 1, // 数量包含已委托 0-未选择 1-选择 ！！！另外的接口获取，真他妈能对付
    receiveType: 0, // 接受方式 0 全部 1-仅邮件
    unProfitLoss: 0, // 未实现盈亏计算方式 0-市场价格 1-标记价格
    stopProfitStopLoss: 0 // 止盈止损 0-未选择 1-选择
  };
};
