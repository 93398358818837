// import tradingApi from '~/server/newTradingApi'
// import { MetaDataType } from './types'

// export const state = () => ({
//   metadata: MetaDataType | null
// });

// export const mutations = {
//   fetchMetadata(state, newData) {
//     state.metadata = newData;
//   }
// };

// export const actions = {
//   fetchMetadataAsync({ commit }) {
//     const data = tradingApi.getMetaData()
//     commit('fetchMetadata', data)
//   }
// };

// export const getters = {
//   getCoinList: (state) => state.metadata.coinList,
//   getContractList: (state) => state.metadata.contractList
// };

import { MUTATION_TYPES, createDefaultStore } from './types';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = () => createDefaultStore();

export { state, MUTATION_TYPES, mutations, actions, getters };
